












import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    OInformationTiles: () => import('@/components/organisms/o-information-tiles.vue'),
    OEventsList: () => import('@/components/organisms/o-events-list.vue'),
    OGlobalTools: () => import('@/components/organisms/o-global-tools.vue')
  },

  setup(_, { root }) {

    const hasAccessTo = (permission: string) => root.$store.getters['user/hasAccessTo'](permission)

    return { hasAccessTo }
  }
})
